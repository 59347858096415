import React, { useState } from "react";
import Checkbox from "react-materialize/lib/Checkbox";
import Col from "react-materialize/lib/Col";
import DatePicker from "react-materialize/lib/DatePicker";
import Row from "react-materialize/lib/Row";
import Select from "react-materialize/lib/Select";
import TextInput from "react-materialize/lib/TextInput";

import bridge from "../../helpers/bridge";
import { submitContribution } from "../../helpers/utils";
import FormLayout from "./FormLayout";
import Hint from "./Hint";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { FormProps } from "src/helpers/forms";

dayjs.extend(utc);
dayjs.extend(customParseFormat);

const START_YEAR = 2019;
const END_YEAR = 2030;

enum DateModeId {
  Date,
  DateAndTime,
  Month,
  Quarter,
  Year,
  SetToUnknown,
}

type DateFields = {
  date: Date;
  time: string;
  timezone: string;
  month: number;
  quarter: number;
  year: number;
};

type DateInputMode = {
  id: DateModeId;
  label: string;
  renderForm: (
    fields: DateFields,
    onChange: (fieldName: string, newValue: any) => void
  ) => any;
};

// Erase types so we can use attributes that are missing from type definitions.
const UntypedDatePicker = DatePicker as any;

function makeYears() {
  const yearOptions = [];
  for (let year = START_YEAR; year <= END_YEAR; year++) {
    yearOptions.push(<option value={year}>{year}</option>);
  }
  return yearOptions;
}

function zeroPad(number: number) {
  if (number < 10) {
    return "0" + number;
  } else {
    return "" + number;
  }
}

const MODES: DateInputMode[] = [
  {
    id: DateModeId.Date,
    label: "Exact date",
    renderForm: (fields, onChange) => (
      <Row>
        <UntypedDatePicker
          s={12}
          label="Date"
          value={
            fields.date ? dayjs(fields.date).format("MMM D, YYYY") : undefined
          }
          options={{ defaultDate: fields.date, setDefaultDate: true }}
          onChange={(date: Date) => onChange("date", date)}
        />
      </Row>
    ),
  },
  {
    id: DateModeId.DateAndTime,
    label: "Exact date and time",
    renderForm: (fields, onChange) => (
      <>
        <Row>
          <UntypedDatePicker
            s={5}
            label="Date"
            value={
              fields.date ? dayjs(fields.date).format("MMM D, YYYY") : undefined
            }
            options={{ defaultDate: fields.date, setDefaultDate: true }}
            onChange={(date: Date) => onChange("date", date)}
          />
          <TextInput
            s={3}
            label="Time"
            value={fields.time}
            onChange={(e) => onChange("time", e.currentTarget.value)}
          />
          <Select
            key="datetime-timezone"
            s={4}
            value={fields.timezone || "Z"}
            onChange={(e) => onChange("timezone", e.currentTarget.value)}
          >
            <option value="+01:00">+01:00</option>
            <option value="+00:00">UTC</option>
            <option value="-04:00">-04:00</option>
            <option value="-05:00">-05:00</option>
          </Select>
        </Row>
      </>
    ),
  },
  {
    id: DateModeId.Month,
    label: "To the Month",
    renderForm: (fields, onChange) => (
      <Row>
        <Select
          key="month-month"
          s={6}
          onChange={(e) => onChange("month", e.currentTarget.value)}
          value={"" + fields.month}
        >
          <option value="" disabled selected>
            Month
          </option>
          <option value="1">January</option>
          <option value="2">February</option>
          <option value="3">March</option>
          <option value="4">April</option>
          <option value="5">May</option>
          <option value="6">June</option>
          <option value="7">July</option>
          <option value="8">August</option>
          <option value="9">September</option>
          <option value="10">October</option>
          <option value="11">November</option>
          <option value="12">December</option>
        </Select>
        <Select
          key="month-year"
          s={6}
          onChange={(e) => onChange("year", e.currentTarget.value)}
          value={"" + fields.year}
        >
          <option value="" disabled selected>
            Year
          </option>
          {makeYears()}
        </Select>
      </Row>
    ),
  },
  {
    id: DateModeId.Quarter,
    label: "To the Quarter (ie. Q1, Q2...)",
    renderForm: (fields, onChange) => (
      <Row>
        <Select
          key="quarter-quarter"
          s={6}
          onChange={(e) => onChange("quarter", e.currentTarget.value)}
          value={"" + fields.quarter}
        >
          <option value="" disabled selected>
            Quarter
          </option>
          <option value="1">Q1</option>
          <option value="4">Q2</option>
          <option value="7">Q3</option>
          <option value="10">Q4</option>
        </Select>
        <Select
          key="quarter-year"
          s={6}
          onChange={(e) => onChange("year", e.currentTarget.value)}
          value={"" + fields.year}
        >
          <option value="" disabled selected>
            Year
          </option>
          {makeYears()}
        </Select>
      </Row>
    ),
  },
  {
    id: DateModeId.Year,
    label: "To the Year",
    renderForm: (fields, onChange) => (
      <Row>
        <Select
          key="year-year"
          s={12}
          onChange={(e) => onChange("year", e.currentTarget.value)}
          value={"" + fields.year}
        >
          <option value="" disabled selected>
            Year
          </option>
          {makeYears()}
        </Select>
      </Row>
    ),
  },
  {
    id: DateModeId.SetToUnknown,
    label: "Make the release date 'Unknown'",
    renderForm: () => <></>,
  },
];

export default function UpdateReleaseDate(props: FormProps) {
  const currentDateString = props.stig.best_citation?.release_after;
  const currentDate = currentDateString ? new Date(currentDateString) : null;
  const now = new Date();

  const [mode, setMode] = useState<DateInputMode>(MODES[0]);

  const [dateFields, setDateFields] = useState<DateFields>({
    date: currentDate,
    time: currentDate ? dayjs.utc(currentDate).format("h:mm a") : null,
    timezone: "+00:00",
    month: (currentDate || now).getUTCMonth() + 1,
    quarter: Math.floor((currentDate || now).getUTCMonth() / 3) * 3 + 1,
    year: (currentDate || now).getUTCFullYear(),
  });
  const [happened, setHappened] = useState<boolean>(false);
  const [region, setRegion] = useState<string>("g:??");
  const [citation, setCitation] = useState<string>();

  const [error, setError] = useState<string | null>(null);

  async function submit() {
    let after: string = undefined;
    let before: string = undefined;

    if (mode.id === DateModeId.Date) {
      after = dayjs(dateFields.date).format("YYYY-MM-DD") + "T00:00:00Z";
      before = dayjs.utc(after).endOf("day").format();
    } else if (mode.id === DateModeId.DateAndTime) {
      const exact = (
        dayjs(dateFields.date).format("YYYY-MM-DD") +
        "T" +
        dateFields.time +
        dateFields.timezone
      ).toLowerCase();
      after = before = dayjs(exact, [
        "YYYY-MM-DDTh:mm aZ",
        "YYYY-MM-DDThh:mm aZ",
        "YYYY-MM-DDTH:mmZ",
        "YYYY-MM-DDTHH:mmZ",
      ]).format();
    } else if (mode.id === DateModeId.Month) {
      after = dayjs(
        `${dateFields.year}-${zeroPad(dateFields.month)}-01T00:00:00Z`
      ).format();
      before = dayjs.utc(after).endOf("month").format();
    } else if (mode.id === DateModeId.Quarter) {
      after = dayjs(
        `${dateFields.year}-${zeroPad(dateFields.quarter)}-01T00:00:00Z`
      ).format();
      before = dayjs.utc(after).add(2, "months").endOf("month").format();
    } else if (mode.id === DateModeId.Year) {
      after = dayjs(`${dateFields.year}-01-01T00:00:00Z`).format();
      before = dayjs.utc(after).endOf("year").format();
    }

    const fields = {
      release_before: before,
      release_after: after,
      citation,
      region,
      happened,
    };

    console.log("Posting", fields, "for", mode.label, dateFields);

    try {
      await submitContribution(props, {
        kind: "UpdateReleaseDate",
        fields,
      });
    } catch (e) {
      setError(e.message);
    }
  }

  const readyToSubmit: boolean = true;

  return (
    <FormLayout readyToSubmit={readyToSubmit} onSubmit={submit} error={error}>
      <Row
        style={{
          margin: "-24px -24px 12px -24px",
          padding: "24px 12px 0 12px",
          backgroundColor: "white",
        }}
      >
        <Select
          s={12}
          label="How specific is the release date?"
          value={mode.label}
          onChange={(e) =>
            setMode(MODES.find((mode) => mode.label === e.currentTarget.value))
          }
        >
          {MODES.map((mode) => (
            <option value={mode.label}>{mode.label}</option>
          ))}
        </Select>
      </Row>
      {mode.renderForm(dateFields, (fieldName, value) => {
        const newFields = { ...dateFields, [fieldName]: value };
        console.log("onChange", fieldName, value, newFields);

        // Hack: Forcibly remove the "overflow: hidden;" CSS rule from the body
        // tag to re-enable scrolling once the modal has closed. This broke
        // around the time that we switched to Preact (though I didn't bisect to
        // find the exact cause).
        document.getElementById("body").removeAttribute("style");

        setDateFields(newFields);
      })}
      <Row>
        {/* TODO: Conditional on the date being in the past */}
        <Col s={12}>
          <Checkbox
            value="happened"
            checked={happened}
            label="Did it actually happen?"
            onChange={(e) =>
              setHappened((e.currentTarget as HTMLInputElement).checked)
            }
          />
        </Col>
        <Hint>Leave unchecked for things that are overdue.</Hint>
      </Row>
      <Row>
        <Select
          s={12}
          label="Region"
          value={region}
          onChange={(e) => setRegion(e.currentTarget.value)}
        >
          {(bridge.data.regions || []).map((region) => (
            <option value={region.regid}>
              {region.emoji} {region.label}
            </option>
          ))}
        </Select>
      </Row>
      <Row>
        <TextInput
          s={12}
          label="Citation (Optional)"
          value={citation}
          onChange={(e) => setCitation(e.currentTarget.value)}
        />
        <Hint collapseTopMargin>
          How did you learn about this release date? URLs to web pages are best,
          but you can type anything here.
        </Hint>
      </Row>
    </FormLayout>
  );
}
