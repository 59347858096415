import React, { useState } from "react";
import Row from "react-materialize/lib/Row";
import Textarea from "react-materialize/lib/Textarea";
import TextInput from "react-materialize/lib/TextInput";
import { submitContribution } from "../../helpers/utils";
import FormLayout from "./FormLayout";
import Hint from "./Hint";
import { FormProps } from "src/helpers/forms";

export default function SetDescription(props: FormProps) {
  const [newDescription, setNewDescription] = useState<string | null>(
    props.stig.description
  );
  const [newSource, setNewSource] = useState<string | null>(
    props.stig.description_source
  );
  const [error, setError] = useState<string | null>(null);

  async function submit() {
    try {
      await submitContribution(props, {
        kind: "SetDescription",
        fields: {
          description: newDescription,
          source: newSource,
        },
      });
    } catch (e) {
      setError(e.message);
    }
  }

  const readyToSubmit: boolean = !!newDescription;

  return (
    <FormLayout readyToSubmit={readyToSubmit} onSubmit={submit} error={error}>
      <Row>
        <Textarea
          s={12}
          label="Description"
          value={newDescription}
          onChange={(e) => setNewDescription(e.currentTarget.value)}
        />
      </Row>
      <Row>
        <TextInput
          s={12}
          label="Source URL (Optional)"
          value={newSource}
          onChange={(e) => setNewSource(e.currentTarget.value)}
        />
        <Hint>
          {/* TODO: Make this prettier. */}
          If you copied this description from another site, paste the URL here.
        </Hint>
      </Row>
    </FormLayout>
  );
}
