import React from "react";
import ReactDOM from "react-dom";

import ToolboxButton from "./roots/ToolboxButton";
import CantWaitButton, { UserStatus } from "./roots/CantWaitButton";

export function detectHosts(root: Element | Document) {
  const improveButtonHosts = root.getElementsByClassName("toolbox-holder");

  for (let i = 0; i < improveButtonHosts.length; i++) {
    const host = improveButtonHosts.item(i);

    const stigId = parseInt(host.getAttribute("data-stig-id"), 10);
    const shouldAutoload = !host.hasAttribute("data-defer-load");
    const isMiniButton = host.hasAttribute("data-mini-button");

    ReactDOM.render(
      <ToolboxButton
        stigId={stigId}
        autoLoadStigInfo={shouldAutoload}
        isMiniButton={isMiniButton}
      />,
      host
    );
  }

  const cantWaitButtonHosts = root.getElementsByClassName(
    "cantwaitbutton-holder"
  );

  for (let i = 0; i < cantWaitButtonHosts.length; i++) {
    const host = cantWaitButtonHosts.item(i);

    const stigId = parseInt(host.getAttribute("data-stig-id"), 10);
    const userStatus: UserStatus = host.getAttribute(
      "data-stig-user-status"
    ) as UserStatus;

    ReactDOM.render(
      <CantWaitButton stigId={stigId} userStatus={userStatus} />,
      host
    );
  }
}

detectHosts(document);
