import React, { useEffect, useState } from "react";
import Icon from "react-materialize/lib/Icon";
import Toolbox from "../components/Toolbox";
import { StigInfo } from "../helpers/types";

export default function ToolboxButton(props: {
  stigId: number;
  autoLoadStigInfo: boolean;
  isMiniButton: boolean;
}) {
  const [toolboxVisible, setToolboxVisible] = useState(false);
  const [stigInfo, setStigInfo] = useState<StigInfo | null>(null);
  const [loadStigInfo, setLoadStigInfo] = useState(
    props.autoLoadStigInfo || false
  );

  useEffect(() => {
    if (!loadStigInfo) {
      return;
    }

    fetch(`/stigs/${props.stigId}.json`, {
      method: "GET",
    }).then(async (response) => {
      const json = await response.json();
      console.log("Loaded stig info", json.stig);
      setStigInfo(json.stig);
    });
  }, [props.stigId, loadStigInfo]);

  useEffect(() => {
    if (toolboxVisible && !loadStigInfo) {
      setLoadStigInfo(true);
    }
  }, [toolboxVisible, loadStigInfo]);

  return (
    <div style={{ position: "relative" }}>
      <a
        className={`waves-effect waves-light btn grey fullwidth`}
        onClick={() => setToolboxVisible(true)}
      >
        <Icon left>{toolboxVisible ? "sync" : "edit"}</Icon>
        {props.isMiniButton ? "" : " Improve Page"}
      </a>

      {toolboxVisible && stigInfo && (
        <Toolbox onDismissed={() => setToolboxVisible(false)} stig={stigInfo} />
      )}
    </div>
  );
}
