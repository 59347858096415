import { Uppy } from "@uppy/core";
import StatusBar from "@uppy/react/lib/StatusBar";
import DragDrop from "@uppy/react/lib/DragDrop";
import XHRUpload from "@uppy/xhr-upload";
import React, { useMemo } from "react";
import Row from "react-materialize/lib/Row";
import Col from "react-materialize/lib/Col";
import { getCSRFToken } from "../../helpers/utils";
import { FormProps } from "../../helpers/forms";

function makeUppy(stigId: number, fieldName: string) {
  const uppy = new Uppy({
    autoProceed: true,
    allowMultipleUploads: false,
    restrictions: { maxNumberOfFiles: 1 },
  });
  uppy.use(XHRUpload, {
    endpoint: `/stigs/${stigId}/artwork?ajax=1`,
    headers: { "X-CSRFToken": getCSRFToken() },
    fieldName,
  });
  uppy.on("upload-success", (file, response) => {
    document.getElementById("hero").innerHTML = response.body.hero;
  });
  return uppy;
}

export default function UploadArtwork(props: FormProps) {
  const posterUppy = useMemo(() => makeUppy(props.stig.id, "poster"), []);
  const splashUppy = useMemo(() => makeUppy(props.stig.id, "splash"), []);

  return (
    <div className="artwork-upload" style={{ height: 280, overflow: "hidden" }}>
      <Row>
        <Col s={12}>
          <DragDrop
            className={"splash-upload"}
            uppy={splashUppy}
            height={200}
            locale={{
              strings: {
                dropHereOr: "Drop splash artwork here or %{browse}",
              },
            }}
          />
          <StatusBar
            className={"splash-upload-statusbar"}
            uppy={splashUppy}
            hideAfterFinish={false}
          />
          <div
            style={{
              position: "absolute",
              top: 56,
              left: 16,
              width: 155,
              height: 170,
              zIndex: 1002,
            }}
          >
            {" "}
            <DragDrop
              className={"poster-upload"}
              uppy={posterUppy}
              height={200}
              locale={{
                strings: { dropHereOr: "Drop poster here or %{browse}" },
              }}
            />
            <StatusBar uppy={posterUppy} hideAfterFinish={false} />
          </div>
        </Col>
      </Row>
    </div>
  );
}
