import { detectHosts } from "../index";
import { FormProps } from "./forms";

function getCookie(name) {
  var cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

export function getCSRFToken(): string {
  return getCookie("csrftoken");
}

export async function submitContribution(
  formProps: FormProps,
  json: any
): Promise<void> {
  const calendarRow = formProps.toolboxDiv.closest(".tilerow");

  const response = await fetch(`/stigs/${formProps.stig.id}/contribute`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRFToken": getCookie("csrftoken"),
    },
    body: JSON.stringify({ ...json, renderCalendarRow: true }),
  });

  if (response.ok) {
    if (calendarRow) {
      // Update the calendar row with the new row as rendered by the server.
      const parent = calendarRow.parentElement;
      const childIndex = Array.from(parent.children).indexOf(calendarRow);
      calendarRow.outerHTML = (await response.json()).calendarRow;
      // Activate react hosts in new row.
      detectHosts(parent.children[childIndex]);
    } else {
      // Reload the page so that the change is reflected.
      document.location.reload();
    }
  } else {
    console.log("Error", response);

    let error: string;
    try {
      const errorObject = await response.json();
      error = errorObject.error;
    } catch {
      error = "There was an unknown problem submitting this contribution.";
    } finally {
      throw new Error(error);
    }
  }
}
