import React, { useState } from "react";
import Row from "react-materialize/lib/Row";
import TextInput from "react-materialize/lib/TextInput";
import { submitContribution } from "../../helpers/utils";
import FormLayout from "./FormLayout";
import { FormProps } from "src/helpers/forms";

export default function ChangeTitle(props: FormProps) {
  const [newTitle, setNewTitle] = useState<string | null>(props.stig.title);
  const [error, setError] = useState<string | null>(null);

  async function submit() {
    try {
      await submitContribution(props, {
        kind: "ChangeTitle",
        fields: {
          title: newTitle,
        },
      });
    } catch (e) {
      setError(e.message);
    }
  }

  const readyToSubmit: boolean = !!newTitle;

  return (
    <FormLayout readyToSubmit={readyToSubmit} onSubmit={submit} error={error}>
      <Row>
        <TextInput
          s={12}
          label="New title"
          value={newTitle}
          onChange={(e) => setNewTitle(e.currentTarget.value)}
        />
      </Row>
    </FormLayout>
  );
}
