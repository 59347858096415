import React from "react";
import Row from "react-materialize/lib/Row";
import Button from "react-materialize/lib/Button";
import Col from "react-materialize/lib/Col";
import Icon from "react-materialize/lib/Icon";

type Props = {
  children: any;
  readyToSubmit: boolean;
  onSubmit: () => void;
  error?: string;
};

export default function FormLayout(props: Props) {
  return (
    <>
      <div style={{ padding: 24 }}>
        {props.children}
        <Row>
          <Col s={12}>
            <Button
              disabled={!props.readyToSubmit}
              waves="light"
              onClick={props.onSubmit}
            >
              Update <Icon right>send</Icon>
            </Button>
          </Col>
        </Row>
      </div>
      {props.error && (
        <div
          id="toolbox-error"
          className="card-content"
          style={{ display: "block" }}
        >
          {props.error}
        </div>
      )}
    </>
  );
}
