import React, { useState } from "react";
import Cookies from "js-cookie";

export type UserStatus = "nouser" | "unlisted" | "listed" | "archived";

type ButtonState = {
  label: string;
  icon: string;
  hasDropdown?: true;
  classNames: string;
  archiveUnarchiveHintText?: string;
  archiveUnarchiveButtonText?: string;
  archiveUnarchiveButtonClassNames?: string;
  archiveUnarchiveButtonIcon?: string;
  archiveUnarchiveButtonVerb?: string;
  archiveUnarchiveButtonNewStatus?: UserStatus;
  removeHintText?: string;
};

const BUTTON_STATES: Record<UserStatus, ButtonState> = {
  archived: {
    label: "In your archive",
    icon: "archive",
    hasDropdown: true,
    classNames: "waves-light green",
    archiveUnarchiveHintText: "Put it back on your list.",
    archiveUnarchiveButtonText: "Unarchive",
    archiveUnarchiveButtonIcon: "favorite",
    archiveUnarchiveButtonVerb: "unarchive",
    archiveUnarchiveButtonNewStatus: "listed",
    removeHintText: "Wish you'd never listed it?",
  },
  listed: {
    label: "On your list",
    icon: "favorite",
    hasDropdown: true,
    classNames: "waves-light userchoice",
    archiveUnarchiveHintText:
      "Remove it from your list but archive it for safe-keeping.",
    archiveUnarchiveButtonText: "Archive",
    archiveUnarchiveButtonClassNames: "green-border",
    archiveUnarchiveButtonIcon: "archive",
    archiveUnarchiveButtonVerb: "archive",
    archiveUnarchiveButtonNewStatus: "archived",
    removeHintText: "Not excited any more?",
  },
  unlisted: {
    label: "I Can't Wait!",
    icon: "favorite_border",
    classNames: "outlined",
  },
  nouser: {
    label: "I Can't Wait!",
    icon: "favorite_border",
    classNames: "outlined",
  },
};

export default function CantWaitButton(props: {
  stigId: number;
  userStatus: UserStatus;
}) {
  const [status, setStatus] = useState(props.userStatus);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const buttonState = BUTTON_STATES[status];

  async function update(verb: string, newStatusOnSuccess: UserStatus) {
    const originalStatus = status;
    setStatus(newStatusOnSuccess);

    const updateUrl = `/stigs/${props.stigId}/update`;

    if (status === "nouser") {
      // Build a form and submit it.
      var form = document.createElement("form");
      form.setAttribute("method", "POST");
      form.setAttribute("action", updateUrl);

      const params = {
        [verb]: verb,
        csrfmiddlewaretoken: Cookies.get("csrftoken"),
      };

      for (var key in params) {
        var hiddenField = document.createElement("input");
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("name", key);
        hiddenField.setAttribute("value", params[key]);
        form.appendChild(hiddenField);
      }

      document.body.appendChild(form);
      form.submit();
    } else {
      const formData: FormData = new FormData();
      formData.set(verb, verb);
      formData.set("ajax", "yes");

      const result = await fetch(updateUrl, {
        method: "POST",
        headers: [["X-CSRFToken", Cookies.get("csrftoken")]],
        body: formData,
      });

      if (result.status === 200) {
        setDropdownVisible(false);
      } else {
        // Revert the status
        setStatus(originalStatus);
        window.alert(
          "Unexpected response in CantWaitButton.update: " + result.status
        );
      }
    }
  }

  return (
    <form>
      <button
        className={`btn cantwait fullwidth waves-effect ${
          dropdownVisible ? "faded" : ""
        } ${buttonState.classNames}`}
        onClick={(e) => {
          if (buttonState.hasDropdown) {
            setDropdownVisible(!dropdownVisible);
          } else {
            update("follow", "listed");
          }
          e.preventDefault();
          return false;
        }}
      >
        <i className="material-icons left">{buttonState.icon}</i>
        <span className="label">{buttonState.label}</span>
        {buttonState.hasDropdown && (
          <i className="material-icons expand">expand_more</i>
        )}
      </button>

      {dropdownVisible && (
        <div className="remove-options this-shouldnt-be-here">
          <p className="status">{buttonState.label}</p>
          <p className="hint">{buttonState.archiveUnarchiveHintText}</p>
          <button
            className={`btn fullwidth outlined waves-effect ${
              buttonState.archiveUnarchiveButtonClassNames || ""
            }`}
            onClick={(e) => {
              update(
                buttonState.archiveUnarchiveButtonVerb,
                buttonState.archiveUnarchiveButtonNewStatus
              );
              e.preventDefault();
              return false;
            }}
          >
            <i className="material-icons left">
              {buttonState.archiveUnarchiveButtonIcon}
            </i>
            {buttonState.archiveUnarchiveButtonText}
          </button>
          <p className="hint">{buttonState.removeHintText}</p>
          <button
            className="btn fullwidth outlined waves-effect red-border"
            onClick={(e) => {
              update("unfollow", "unlisted");
              e.preventDefault();
              return false;
            }}
          >
            <i className="material-icons left">cancel</i>Remove
          </button>
        </div>
      )}
    </form>
  );
}
