import React from "react";
import Icon from "react-materialize/lib/Icon";

type Props = {
  icon: string;
  title: string;
  onClick: () => void;
};

export default function SectionButton(props: Props) {
  return (
    <div className="toolbox-section">
      <a className="waves-effect waves-teal btn-flat" onClick={props.onClick}>
        <Icon left>{props.icon}</Icon>
        <span>{props.title}</span>
      </a>
    </div>
  );
}
