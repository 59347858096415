import React, { useRef, useState } from "react";
import SectionButton from "./SectionButton";
import SetCategory from "./forms/SetCategory";
import Icon from "react-materialize/lib/Icon";
import SetDescription from "./forms/SetDescription";
import SetAuthor from "./forms/SetAuthor";
import ChangeTitle from "./forms/ChangeTitle";
import UpdateReleaseDate from "./forms/UpdateReleaseDate";
import { StigInfo } from "src/helpers/types";
import UploadArtwork from "./forms/UploadArtwork";
import { FormProps } from "src/helpers/forms";

type Props = {
  onDismissed: () => void;
  stig: StigInfo;
};

type Section = {
  title: string;
  icon: string;
  SectionComponent?: React.FunctionComponent<FormProps>;
};

function makeSections(stig?: StigInfo): { [key: string]: Section } {
  const authorSection = stig?.category
    ? {
        SetAuthor: {
          icon: stig.author_icon,
          title: stig.author_mutation_description,
          SectionComponent: SetAuthor,
        },
      }
    : {};

  return {
    SetCategory: {
      icon: "all_inbox",
      title: "Change category",
      SectionComponent: SetCategory,
    },
    ...authorSection,
    UpdateReleaseDate: {
      icon: "calendar_today",
      title: "Update the release date",
      SectionComponent: UpdateReleaseDate,
    },
    SetDescription: {
      icon: "description",
      title: "Update the description",
      SectionComponent: SetDescription,
    },
    ChangeTitle: {
      icon: "title",
      title: "Change the title",
      SectionComponent: ChangeTitle,
    },
    UploadArtwork: {
      icon: "add_photo_alternate",
      title: "Add artwork",
      SectionComponent: UploadArtwork,
    },
  };
}

export default function Toolbox(props: Props) {
  const sections = makeSections(props.stig);
  const toolboxRef = useRef<HTMLDivElement>();

  // TODO: Remove IDs, use inline styles or stylesets instead

  const [activeSectionKey, setActiveSectionKey] = useState<string | null>(null);
  const activeSection: Section | undefined = sections[activeSectionKey];

  return (
    <div
      ref={toolboxRef}
      id="toolbox"
      className="card grey lighten-4"
      style={{ display: "block" }}
    >
      {activeSection ? (
        <>
          <div id="toolbox-title">
            <a
              id="toolbox-close-button"
              className="waves-effect waves-teal btn-flat white-text"
              onClick={() => setActiveSectionKey(null)}
            >
              <Icon left>arrow_back</Icon>
            </a>
            <div id="toolbox-title-content">{activeSection.title}</div>
          </div>
          <activeSection.SectionComponent
            stig={props.stig}
            toolboxDiv={toolboxRef.current}
          />
        </>
      ) : (
        <>
          <div id="toolbox-title">
            <a
              id="toolbox-close-button"
              className="waves-effect waves-teal btn-flat white-text"
              onClick={props.onDismissed}
            >
              <Icon left>close</Icon>
            </a>
            <div id="toolbox-title-content">Improve Page</div>
          </div>
          <div>
            {Object.entries(sections).map(([key, section]) => (
              <SectionButton
                key={key}
                icon={section.icon}
                title={section.title}
                onClick={() => setActiveSectionKey(key)}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
}
