import React from "react";
import Col from "react-materialize/lib/Col";

export default function Hint(props: {
  children: any;
  collapseTopMargin?: true;
}) {
  // TODO: Style this.
  return (
    <Col
      s={12}
      style={{
        fontSize: "0.9em",
        marginTop: props.collapseTopMargin ? -15 : 0,
        marginBottom: 25,
        color: "#777",
      }}
    >
      {props.children}
    </Col>
  );
}
