type BridgeData = {
  settable_categories?: {
    name: string;
    title: string;
  }[];
  regions?: {
    regid: string;
    emoji: string;
    label: string;
  }[];
};

class Bridge {
  data: BridgeData = {};

  constructor() {
    const bridgeDataElement = document.getElementById("bridge-data");
    if (bridgeDataElement) {
      this.data = JSON.parse(bridgeDataElement.textContent);
    }
  }
}

export default new Bridge();
