import React, { useState } from "react";
import Row from "react-materialize/lib/Row";
import Select from "react-materialize/lib/Select";
import bridge from "../../helpers/bridge";
import { submitContribution } from "../../helpers/utils";
import FormLayout from "./FormLayout";
import { FormProps } from "src/helpers/forms";

export default function SetCategory(props: FormProps) {
  const [newCategory, setNewCategory] = useState<string | null>(
    props.stig.category
  );
  const [error, setError] = useState<string | null>(null);

  async function submit() {
    try {
      await submitContribution(props, {
        kind: "SetCategory",
        fields: {
          name: newCategory,
        },
      });
    } catch (e) {
      setError(e.message);
    }
  }

  const readyToSubmit: boolean = !!newCategory;

  return (
    <FormLayout readyToSubmit={readyToSubmit} onSubmit={submit} error={error}>
      <Row>
        <Select
          s={12}
          value={newCategory}
          onChange={(e) => setNewCategory(e.currentTarget.value)}
        >
          <option value="" disabled selected>
            Category
          </option>
          {(bridge.data.settable_categories || []).map((category) => (
            <option value={category.name}>{category.title}</option>
          ))}
        </Select>
      </Row>
    </FormLayout>
  );
}
